
































import { Component, Prop, Ref } from 'vue-property-decorator'
import Hls from 'hls.js'
import Plyr from 'plyr'

// mixins
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
// store
import { MediaResource, UserLessonHlsPartResource } from '@/store/types'
// utils
import { plyrConfig } from '@/utils/plugins/plyr'
import MasterLessonsModule from '@/store/modules/master/lessons'

/**
 *  Для работы компонента обязательна привязка ивента videoInitialized
 */
@Component
export default class VideoCustom extends DetectSafariMixin {
  @Ref() plyr!: HTMLVideoElement

  @Prop({ default: false })
  private isStarted!: boolean

  @Prop({ required: true })
  private video!: MediaResource

  @Prop({ default: false })
  private poster!: boolean

  @Prop({ default: () => ([]) })
  private hlsFormats!: UserLessonHlsPartResource[]

  @Prop({ default: null })
  private saveTimecode!: number | null

  @Prop({ required: true })
  private lessonID!: number

  @Prop({ required: true })
  private masterGroupID!: number

  private posterImg = require('@/assets/images/stub/video-poster-2.jpg')
  private mainEl: HTMLDivElement | null = null

  private config: any = plyrConfig
  private player: any = null

  private interval: number | null = null

  private method = this.saveTimeLesson.bind(this, true)

  private events = ['playing', 'play', 'pause', 'volumechange', 'seeking', 'seeked', 'ratechange', 'ended', 'controlshidden', 'controlsshown', 'qualitychange']

  private checkIsIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  private mounted() {
    window.addEventListener('beforeunload', this.method)
    if (this.video.hls) {
      this.hlsInit()
    } else {
      this.plyrInit()
    }
  }

  private beforeDestroy() {
    this.saveTimeLesson()
    this.clearInterval()
    window.removeEventListener('beforeunload', this.method)
    this.player?.destroy()
  }

  private hlsInit() {
    if (!Hls.isSupported()) {
      if (this.isSafariOnIOS) {
        const availableQualities = [0, ...this.hlsFormats.map(q => +q.label)]
        this.config = {
          ...this.config,
          i18n: {
            ...this.config.i18n,
            qualityLabel: {
              0: 'Авто',
            },
          },
          quality: {
            default: 0,
            options: availableQualities,
            forced: true,
          },
        }
        this.plyrInit()
      } else {
        this.plyrInit()
      }
    } else {
      const hls = new Hls()
      hls.loadSource(this.video.hls as string)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = hls.levels.map(l => l.height)
        availableQualities.unshift(0)
        this.config = {
          ...this.config,
          i18n: {
            ...this.config.i18n,
            qualityLabel: {
              0: 'Авто',
            },
          },
          quality: {
            default: 0,
            options: availableQualities,
            forced: true,
            onChange: (e: any) => this.updateQuality(e),
          },
        }

        hls.on(Hls.Events.LEVEL_SWITCHED, (event: any, data: any) => {
          const span = document.querySelector('.plyr__menu__container [data-plyr="quality"][value="0"] span')
          if (span)
            span.innerHTML = hls.autoLevelEnabled ? `Авто (${hls.levels[data.level].height}p)` : 'Авто'
        })

        this.plyrInit()
      })

      hls.attachMedia(this.plyr)
      window.hls = hls
    }
  }

  private plyrInit() {
    this.player = new Plyr(this.plyr, this.config)

    this.mainEl = document.querySelector('.v-main')
    this.player.on('ready', () => this.$emit('videoInitialized', this.plyr))

    if (this.player.playing !== true && this.checkIsIOS) {
      this.player.once('canplay', () => {
        if (this.saveTimecode !== null) {
          this.player.currentTime = this.saveTimecode
        }
      })
    }

    this.player.on('enterfullscreen', () => {
      if (this.mainEl) {
        this.mainEl.style.position = 'relative'
        this.mainEl.style.zIndex = '10'
      }
      document.activeElement && (document.activeElement as any).blur()
    })

    this.player.on('exitfullscreen', () => {
      if (this.mainEl) {
        this.mainEl.style.position = ''
        this.mainEl.style.zIndex = ''
      }
      document.activeElement && (document.activeElement as any).blur()
    })

    this.events.forEach(event => {
      this.player.on(event, () => document.activeElement && (document.activeElement as any).blur())
    })

    this.player.on('pause', () => {
      this.saveTimeLesson()
      this.clearInterval()
    })

    this.player.on('ended', () => {
      this.clearInterval()
      MasterLessonsModule.saveTimecodeLesson({
        lessonID: this.lessonID,
        masterGroupID: this.masterGroupID,
        seconds: 0,
        unload: false,
      })
      localStorage.removeItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`)
    })

    this.player.on('play', () => {
      this.interval = setInterval(this.saveTimeInLocalStorage, 5000)
    })
  }

  private updateQuality(e: number) {
    if (e === 0) {
      window.hls.currentLevel = -1
    } else {
      window.hls.levels.forEach((level: any, levelIndex: number) => {
        if (level.height === e) {
          window.hls.currentLevel = levelIndex
        }
      })
    }
  }

  private loadedmetadata() {
    if (this.plyr) {
      if (this.saveTimecode !== null && !this.checkIsIOS) {
        this.plyr.currentTime = this.saveTimecode
      }
      this.$emit('readymetadata', this.plyr.duration)
    }
  }

  // вызывается при паузе на плеере или событии beforeUnload
  private saveTimeLesson(unload = false) {
    const seconds = Math.floor(this.plyr.currentTime)
    if (this.saveTimecode !== seconds && Math.floor(this.plyr.duration) !== seconds) {
      MasterLessonsModule.saveTimecodeLesson({
        lessonID: this.lessonID,
        masterGroupID: this.masterGroupID,
        seconds,
        unload,
      })
    }
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  private saveTimeInLocalStorage() {
    localStorage.setItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`, JSON.stringify({
      seconds: Math.floor(this.plyr?.currentTime || this.player.currentTime),
      ts: Date.now(),
    }))
  }
}
